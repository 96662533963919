import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export interface Breadcrumb {
  label: string
  path: string | null
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/reports',
    name: 'Reports',
    meta: {
      breadcrumbs: [
        {
          label: 'Reports',
          path: null
        }
      ]
    },
    component: () => import('@/views/ReportList.vue')
  },
  {
    path: '/reports/:id',
    name: 'DataComparison',
    meta: {
      breadcrumbs: [
        {
          label: 'Reports',
          path: '/reports'
        },
        {
          label: ':reportName',
          path: null
        }
      ]
    },
    component: () => import('@/views/DataComparison.vue')
  },
  {
    path: '/',
    redirect: '/reports'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
