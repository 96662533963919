import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import DataComparisonApp from './apps/DataComparisonApp.vue'
import DspaceApp from './apps/DspaceApp.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

let app
const splitPathname = location.pathname.split('/')
const pathname = splitPathname[1]

if (pathname === 'data-comparison-app') {
  app = createApp(DataComparisonApp).use(createPinia())
} else if (pathname === 'dspace-app') {
  app = createApp(DspaceApp).use(createPinia())
} else {
  app = createApp(App).use(store).use(router).use(createPinia())
}

app.mount('#app')
