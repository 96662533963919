import { defineStore } from 'pinia'
import { RAPI_DOMAIN, RAPI_VERSION } from '@/environment'

const rapiUrl = `${RAPI_DOMAIN}/${RAPI_VERSION}`

export interface DspaceState {
  collections: Array<any>
  items: Array<any>
}
export const useDspaceStore = defineStore('dspace', {
  state: (): DspaceState => {
    return {
      collections: [],
      items: []
    }
  },
  actions: {
    async fetchCollections () {
      const collectionsRes = await fetch(`${rapiUrl}/collections`)
      const collections: Array<any> = await collectionsRes.json()
      this.collections = [...collections]
      return this.collections
    },
    async fetchItems (colName?: string) {
      const filter = colName
        ? { where: { collection: colName } } : {}
      const itemsRes = await fetch(`${rapiUrl}/items?filter=${JSON.stringify(filter)}`)
      const items: Array<any> = await itemsRes.json()
      this.items = [...items]
      return this.items
    }
  }
})
