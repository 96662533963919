import { defineStore } from 'pinia'
import { RAPI_DOMAIN, RAPI_VERSION } from '@/environment'

const rapiUrl = `${RAPI_DOMAIN}/${RAPI_VERSION}`

export interface DataComparisonState {
  selectedReportMetadata: any,
  reportMetadata: any[],
  report: any
}
export const useDataComparisonStore = defineStore('dataComparison', {
  state: (): DataComparisonState => {
    return {
      selectedReportMetadata: null,
      reportMetadata: [],
      report: null
    }
  },
  actions: {
    async fetchReportMetadata () {
      const reportMetadataRes = await fetch(`${rapiUrl}/report-metadata?filter[where][archive][ne]=true`)
      const reportMetadata: Array<any> = await reportMetadataRes.json()
      this.reportMetadata = [...reportMetadata]
      return this.reportMetadata
    },
    async fetchReport (id: string) {
      const reportsRes = await fetch(`${rapiUrl}/reports/${id}`)
      const report: any = await reportsRes.json()
      this.report = { ...report }
      return this.report
    },
    setSelectedReportMetadata (id: string) {
      this.selectedReportMetadata = this.reportMetadata.find(e => e._id === id)
      return this.selectedReportMetadata
    }
  }
})
